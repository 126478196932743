<template>
  <div class="ui fluid container">
    <orders-search @update="search" />
    <div class="ui raised segment">
      <table class="ui selectable striped table">
        <thead>
          <tr>
            <th>Reference</th>
            <!-- <th>Due Date</th> -->
            <th>Status</th>
            <th>Sales Rep</th>
            <th>Customer</th>
            <th>Creative</th>
            <th>Vectorization</th>
            <th>Digitizing</th>
            <th>Vinyl</th>
            <th>Names - Embroidery</th>
            <th>Names - Vinyl</th>
            <th>Proof</th>
            <th>Rush</th>
            <th colspan="2">Total cost</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading.invoicing">
            <td colspan="10"><i class="loading spinner icon"></i></td>
          </tr>
          <tr v-else-if="orders.length === 0">
            <td colspan="10">No orders match the search criteria.</td>
          </tr>
          <router-link
            custom
            v-slot="{ navigate }"
            v-for="order in orders"
            :key="order.id"
            :to="{ name: 'Order', params: { id: order.id } }"
          >
            <tr @click="navigate">
              <td>{{ order.reference_number }} - {{ order.sequence }}</td>
              <!-- <td>
                {{
                  order.date
                    ? DateTime.fromISO(order.date).toLocaleString(
                        DateTime.DATE_MED
                      )
                    : "-"
                }}
              </td> -->
              <td
                :class="{
                  grey: order.status === 'DR',
                  green:
                    !['DR', 'CA', 'FI'].includes(order.status) &&
                    !order.art_redo &&
                    !order.creative_redo &&
                    !order.proof_redo,
                  black: order.status === 'FI',
                  orange:
                    order.art_redo || order.creative_redo || order.proof_redo,
                  blue: order.status === 'CA'
                }"
              >
                {{ order.status === "DR" ? "Draft" : "" }}
                {{ order.status === "CR" ? "Creating" : "" }}
                {{ order.status === "CA" ? "Creative approval" : "" }}
                {{ order.status === "PR" ? "Proofing" : "" }}
                {{ order.status === "PA" ? "Proof approval" : "" }}
                {{ order.status === "CO" ? "Converting" : "" }}
                {{ order.status === "FI" ? "Finished" : "" }}
                <i
                  v-if="order.art_redo || order.creative_redo || order.proof_redo"
                  class="warning icon"
                  title="One or more assets are being reworked."
                ></i>
              </td>
              <td>{{ order.sales_rep_email }}</td>
              <td>{{ order.customer_email }}</td>
              <td>${{ order.creative_cost ? order.creative_cost : "-" }}</td>
              <td>
                ${{ order.vectorization_cost ? order.vectorization_cost : "-" }}
              </td>
              <td>
                ${{ order.digitizing_cost ? order.digitizing_cost : "-" }}
              </td>
              <td>
                ${{ order.vinyl_cost ? order.vinyl_cost : "-" }}
              </td>
              <td>${{ order.names_digitizing_cost ? order.names_digitizing_cost : "-" }}</td>
              <td>${{ order.names_vinyl_cost ? order.names_vinyl_cost : "-" }}</td>
              <td>${{ order.proof_cost ? order.proof_cost : "-" }}</td>
              <td>${{ order.rush_cost ? order.rush_cost : "-" }}</td>
              <td>${{ order.total_cost ? order.total_cost : "-" }}</td>
              <td><i class="right chevron icon" /></td>
            </tr>
          </router-link>
        </tbody>
      </table>
      
      <p v-if="paginator.count">
        Displaying results {{ (paginator.currentPage - 1) * 10 + 1 }}-{{
          (paginator.currentPage - 1) * 10 + 11 > paginator.count
            ? paginator.count
            : (paginator.currentPage - 1) * 10 + 11
        }}
        of {{ paginator.count }}.
      </p>
      <div v-if="paginator.count" class="ui pagination menu">
        <a
          v-for="page in paginator.pages"
          :key="page"
          :class="{ active: page === paginator.currentPage }"
          @click="page !== paginator.currentPage ? changePage(page) : () => {}"
          class="item"
        >
          {{ page }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import OrdersSearch from "@/components/OrdersSearch";
import { DateTime } from "luxon";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    OrdersSearch
  },

  data() {
    return {
      lastQuery: "",

      paginator: {
        count: 0,
        currentPage: 1,
        pages: null
      },

      loading: {
        invoicing: true
      },

      DateTime
    };
  },

  computed: {
    ...mapState(["currentBusinessId", "orders"])
  },

  methods: {
    search(query) {
      this.paginator.currentPage = 1;
      this.lastQuery = query;
      this.loading.invoicing = true;
      this.loadInvoicing({ query }).then(data => {
        this.paginator.count = data.count;
        this.paginator.pages = data.pages;
        this.loading.invoicing = false;
      });
    },

    changePage(page) {
      this.paginator.currentPage = page;
      let query;
      if (this.lastQuery.charAt(0) === "?") {
        query = this.lastQuery + `&page=${page}`;
      } else {
        query = this.lastQuery + `?page=${page}`;
      }

      this.loading.invoicing = true;
      this.loadInvoicing({ query }).then(data => {
        this.paginator.count = data.count;
        this.paginator.pages = data.pages;
        this.loading.invoicing = false;
      });
    },

    ...mapActions(["loadInvoicing"])
  },

  created() {
    this.loadInvoicing().then(data => {
      this.paginator.count = data.count;
      this.paginator.pages = data.pages;
      this.loading.invoicing = false;
    });
  }
};
</script>

<style scoped>
.container {
  padding: 0 3em 0 3em;
}
</style>
